.modal {
  div[class="ant-modal-content"] {
    background: #0a0a0b;
    border-radius: 24px;
    overflow: hidden;
  }

  span[class*="ant-modal-close-icon"] {
    color: #2b2b2b;
    transition: all 250ms linear;

    &:hover {
      color: #01cdfb;
    }
  }

  div[class="ant-modal-body"] {
    padding: 72px 106px;
  }

  div[class="ant-modal-footer"] {
    border-top: 1px solid #2b2b2b;
    padding-top: 16px;
    padding-bottom: 72px;
  }
}

.modalTitle {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: #e9e9e9 !important;
  text-align: center;
  margin-bottom: 32px !important;
}

.modalSubTitle {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: #01cdfb !important;
}

.modalDescription {
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  text-align: center !important;
  color: #565656 !important;
  margin-bottom: 24px !important;
}

.modalEmailLink {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  color: #01cdfb !important;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 16px;
  }

  &:hover a {
    cursor: pointer;
    color: #01cdfb;
  }
}

.modalFooterTitle {
  text-align: center;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  color: #565656 !important;
  margin-bottom: 15px !important;
}

.socialLinksWrap {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  &Mobile {
    height: 50px;
    justify-content: space-evenly;
    & svg {
      width: 50px;
      height: 50px;
    }
  }
}

.socialLink {
  display: inline-block;
  pointer-events: none;

  &:not(:last-child) {
    margin-right: 39px;
  }

  & svg {
    fill: #565656;
    transition: all 250ms linear;
  }

  &:hover svg {
    cursor: default;
  }
}

.socialLinkActive {
  pointer-events: all;

  &:hover svg {
    cursor: pointer;
    fill: #01cdfb;
  }
}

.mobileModal {
  position: absolute;
  bottom: 0px;
  padding-bottom: 0px;
  top: unset;
  animation: slideUp 0.7s;
  width: 100vw !important;
  max-width: unset !important;
  margin-bottom: 0px !important;
  div[class="ant-modal-content"] {
    border-radius: 24px 24px 0px 0px !important;
  }
  div[class="ant-modal-footer"] {
    padding-bottom: 5px;
  }

  div[class="ant-modal-body"] {
    padding: 72px 40px;
  }
}

.mobileModalWrapper {
  overflow: hidden;
}

@keyframes slideUp {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;