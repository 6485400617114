@Sub-font-color: #6f6f6f;
.retailer-header-wrapper {
  justify-content: space-between;
  padding-bottom: 16px;
  .name {
    margin-bottom: 0.1em;
    text-transform: capitalize;
  }

  .address {
    font-weight: 400;
    color: @Sub-font-color;
  }
}

.retailer-details__info-row {
  padding: 8px 0px;
  align-items: flex-start;
}

.map-wrapper {
  height: 300px;
  border: 2px solid rgba(34, 34, 34, 0.1);
  border-radius: 10px;
}

.retailers-pickups-table {
  margin: 16px 0;
}

.document-wrapper {
  padding: 5px;
  .ant-space-item {
    align-self: center;
  }
  &__title {
    font-weight: 700;
    text-align: center;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;