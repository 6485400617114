@import "../../../../variables.less";
.uploadStepsWrapper {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.uploadDocumentsWrapper {
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 100%;
  max-width: 370px;
  @media (min-width: 768px) {
    padding: 24px;
    width: 370px;
    margin-right: 30px;
    margin-bottom: 50px;
  }
}
.uploadDragger {
  background: rgba(252, 220, 62, 0.05) !important;
  border: 2px dashed #01cdfb !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 31px;
  text-align: center;
} //
.uploadedDocument {
  width: 322px;
  border: 2px dashed #01cdfb !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 2px;
} //
.uploadText {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: @font-color;
  display: flex;
  flex-direction: column;
} //
.uploadText > span {
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  color: rgba(34, 34, 34, 0.5);
} //
.uploadHint {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: @font-color;
} //
.activeStep {
  font-weight: bold;
}
.draggerStyle {
  border: none !important;
} //
.draggerStyle > span {
  padding: 0 !important;
} //
.nextStepButton {
  background: @primary-color;
  box-shadow: 0px 10px 30px rgba(81, 228, 251, 0.4);
  border-radius: 40px;
  // width:145px;
  color: @font-color;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.previousStepButton {
  color: @font-color;
  padding-left: 0;
  margin-right: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: @font-color;
  @media (min-width: 1024px) {
    margin-right: 300px;
  }
}
.stepAction {
  margin-top: 97px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;