@import "../../../variables.less";

.page-wrapper-price-settings {
    padding: 40px 55px 40px 30px;
    height: auto;
    min-height: 100vh;
    position: relative;

    .content-block-price-settings {
        width: 100%;
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .card_body {
                width: 100% !important;
        }

        .ant-checkbox {
            border-color: #2296F2;
        }

        .table-style {
            width: 100%;

            .ant-table-thead {
                .ant-table-cell {
                    background-color: #F5F7FB;
                }
            }

            .no-border-style {
                border: none;
            }
        }
    }
}

* :hover {
    border-color: #2296F2;
}


.scrollable-table {
    overflow: hidden;
}

.scrollable-table::-webkit-scrollbar {
width: 0px;
background-color: transparent;
}
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;