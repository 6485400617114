@bb_grey_1: #a1b5bc;
@bb_grey_2: #939393;
@bb_grey_3: #939393;
@bb_grey_4: #fafbfd;

.job-details-wrapper {
  border-radius: 10px;

  &.card {
    margin: 40px 20px;
  }

  .card_body,
  .job-details-empty,
  .job-details-loading {
    min-height: 70vh;
  }

  .job-details-empty,
  .job-details-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .job-details-empty {
    flex-direction: column;
  }

  .order-details-wrapper {
    display: flex;
    justify-content: space-between;

    .order-list-wrapper {
      width: 50%;
      max-height: 580px;
      position: relative;
      top: -20px;
      padding-top: 30px;
      overflow: hidden;

      &:hover {
        overflow: auto;
      }
    }

    .job-map-wrapper {
      width: 50%;
      // background-color: @bb_grey_1;
      border-radius: 4px;
      margin-left: 20px;
      // padding: 20px;
    }
  }

  .order-title-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title-text {
      size: 14px;
      display: flex;
      justify-content: space-between;
    }
  }

  .collapse-custom-collapse {
    margin-bottom: 30px;
  }

  .job-dertails-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h4 {
        margin: 0 15px 0 0;
      }
    }
    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-left: 10px;
      }
    }
  }

  .job-info-wrapper {
    width: 60%;
    margin-top: 30px;

    .ant-descriptions-row > th {
      padding-bottom: 10px;
    }
    .ant-descriptions-item-label {
      font-weight: bold;
      color: #3c3c3c73;
    }

    .ant-descriptions-item-content {
      font-weight: bold;
    }
  }
}
.order-list-wrapper {
  margin-top: 25px;
  .ant-timeline-item-content {
    top: -25px;
  }
}

.accordion-wrapper {
  width: 500px;

  .accordion-title-wrapper {
    // height: 50px;
    border: 1px solid @bb_grey_3;
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .acc-sub-title,
    .acc-title > .ant-typography {
      margin: 0;
    }

    .acc-sub-title {
      color: @bb_grey_1;
      font-size: 10px;
    }

    .acc-title {
      display: flex;
      justify-content: space-between;

      .actions-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
  .accordion-panel {
    background-color: @bb_grey_4;
    border-radius: 4px;
    margin-top: 10px;
    padding: 20px;
    display: none;
    opacity: 0;
    transition: all 0.2s ease-out;

    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 20px;
    }

    &.active {
      opacity: 1;
      display: block;
    }
  }
}

.job-stats-wrapper {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 30px 0;

  .stats-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .stats-count {
    font-size: 40px;
    font-weight: bold;
    margin: 0;
  }
  .stats-label {
    color: #767676;
  }
}

.drop-over-downward {
  border-bottom: 2px dashed #1890ff;
}

.drop-over-upward {
  border-top: 2px dashed #1890ff;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;