.confirm_modal {
  &--main_question {
    font-weight: 600;
  }
  &--label {
    margin-right: 10px;
  }
  &--jobselect {
    min-width: 150px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;