.openApi {
  background: rgba(250, 248, 240, 1);
  border: 2px dashed #01cdfb;
  border-radius: 10px;
  padding: 10px 15px;
  overflow-y: visible;
  grid-column-end: -1;
  width: 500px;
  position: relative;
  margin-top: 10px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label {
    display: inline-block;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    margin-right: 5px;
  }

  &__token {
    display: inline-block;
    max-width: 200px;
    height: 36px;
    line-height: 36px;
    overflow: auto;
    margin-right: 5px;
  }

  &__DocBtn {
    cursor: pointer;
    &.ant-btn {
      width: 100%;
      color: black;
      border: rgba(34, 34, 34, 1) 1px solid;
      border-radius: 4px;
      font-weight: 600;
      background: rgba(250, 248, 240, 1);
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;