.datePickerWrapper {
  margin-left: 20px;
  display: inline;
}

.searchReports {
  width: 300px;
}

.mobile-page-wrapper--delivery-reports {
  .delete-report {
    padding: 0 16px 16px 16px;
  }

  .table-item {
    .rows-wrapper {
      .row {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
}
.attachmentScroll {
  width: 90%;
  overflow-x: scroll;
  display: flex;
  gap: 3;
  .ant-image {
    margin-right: 3px;
    flex-shrink: 0;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;