@import "../../../variables.less";

.TableWrapper {
  margin-top: 15px;
  .ant-table-wrapper {
    //max-width: 87vw;
    min-width: 100%;
    .ant-table-content {
      overflow-x: auto;
      //max-width: 87vw;
    }
  }
  table {
    //max-width: 80vw;

    svg {
      cursor: pointer;
    }
  }
  .actionHolder {
    margin-right: 10px !important;
    &.deleteItem {
      svg path {
        fill: @danger-color;
      }
    }

    &.cancelItem {
      svg path {
        fill: @danger-color;
      }
    }

    &.posteponeItem {
      svg path {
        fill: goldenrod;
      }
    }
  }
}

.selectedItemsWrapper {
  margin-top: 10px;
  margin-bottom: 5px;
  color: @success-color;
  font-weight: 600;
  justify-content: center;
}
.actions {
  margin-bottom: 16px;
  max-width: 80vw;

  .downloadAllBarcodes {
    margin-left: auto;
    background: rgba(239, 239, 239, 1);
    border-radius: 5px;
    font-weight: 700;
    width: 140px;
    height: 40px;
  }
}

.bulkActions {
  text-align: left;
  display: flex;
  margin-right: 16px;
  justify-content: flex-start;
  align-items: center;

  & button {
    margin-left: 8px;
  }
}

.changeAddressBtn {
  margin: 0;
  color: #008dff;
  cursor: pointer;
}

.inputsLatLongWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.locationModal__error,
.locationModal__address,
.locationModal__loading {
  text-align: center;
}

.locationModal__error {
  color: red;
}

.notes-popup-content {
  padding: 4px 0px;
  min-height: 130px;

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.75) !important;
  }

  &__input {
    height: 100px !important;
  }

  &__btn-group {
    margin-top: 12px;
    display: flex;
    justify-content: space-around;
  }

  &__notes {
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.phone-popup-content {
  min-height: 80px;

  .react-tel-input {
    input {
      width: 210px;
    }
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  &__btn-group {
    display: flex;
    justify-content: space-around;
  }
}

.reports-image-container {
  // width:80%;
  overflow-x: auto;
  max-height: 500px;
}

.ant-table-thead {
  .ant-table-cell {
    background: rgba(252, 220, 62, 0.3);
    border-right: 2px white solid;
    font-weight: bold;
  }
}

.orders-actions-single-line {
  .downloadAllBarcodes {
    @media (min-width: 760px) {
      position: relative;
      // top: -46px;
      @media (max-width: 1042px) {
        top: -50px;
      }
    }
  }
  @media (min-width: 1155px) {
    justify-content: flex-end;
    // position: relative;
    top: -46px;
    margin-bottom: -32px;
    align-items: center;
    gap: 10px;
    .downloadAllBarcodes {
      margin: 0;
      top: -46px;
    }
    .ant-radio-group {
      margin: 0;
      top: -46px;
      position: relative !important;
    }
    .bulkActions {
      margin-bottom: 42px;

      @media (min-width: 1450px) {
        position: relative;
        top: -46px;
        margin-bottom: 0px;
      }
    }
    // .orders-actions-single-line{
    //   margin: 0;
    //   top: -46px;
    //   position: relative !important;
    // }
  }

  &-nofilters {
    position: static !important;
    .downloadAllBarcodes {
      top: 0px;
      @media (min-width: 760px) {
        position: relative;
        top: 0px;
      }
    }
    .bulkActions {
      margin-bottom: 0px !important;
    }
    @media (min-width: 850px) {
      justify-content: flex-end;
      // position: relative;
      top: -46px;
      margin-bottom: -32px;
      align-items: center;
      gap: 10px;
      .downloadAllBarcodes {
        margin: 0;
        top: -46px;
        @media (min-width: 1155px) {
          margin: 0;
          top: -46px;
        }
      }
      .bulkActions {
        position: relative;
        top: -46px;
        @media (max-width: 850px) {
          top: 0px;
        }
      }
    }
  }
}
.historyDrawer {
  .ant-steps-item-title {
    width: 100%;
    margin-left: 0;
  }
  &-step {
    &-title {
      width: 140px;
      margin-right: none;
      text-transform: capitalize;
    }
  }
  .ant-steps-item-subtitle {
    margin-left: 0px !important ;
  }
}

.options__row {
  margin: 5px 0px;
  justify-content: space-between;
  &--label {
    font-weight: 600;
    margin-right: 7px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;