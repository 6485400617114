@import "../../../variables.less";

@card-light-bg: #ffffff;
@card-shadow: 0px 0px 20px 0px rgba(114, 114, 107, 0.1);
@card-border: 0;
@card-margins : 16px;

.card {
  border: @card-border;
  box-shadow: @card-shadow;
  background-color: @card-light-bg;
  border-radius: 10px;
  margin: 5px 6px;

  .card_header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 60px;
    padding: 15px 25px;
    border-bottom: 0.5px solid #eeeeee;
    .card_header--title {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .card_body {
    padding: 25px;
    &--subtitle {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 15px;
    }
    &--subheader {
      justify-content: space-between;
    }
  }

  &-margins {
    margin:  @card-margins;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;