@import "../../../../utils/styleMixins";

.button {
  border-radius: 40px;
  border: none;
  transition: all 250ms linear;
  background: transparent;
  font-weight: 700;
  width: 214px;
  height: 56px;
  color: white !important;

  .gradientAnimation(124.01deg, #01cdfb 19%, #01acec 86.68%) !important;

  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(124.01deg, #01cdfb 19%, #01acec 86.68%);
    opacity: 0.5;
    display: inline-block;
    width: 182px;
    height: 40px;
    filter: blur(23px);
    border-radius: 40px;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  &:hover {
    cursor: pointer;
    color: #0a0a0b !important;

    .gradientAnimation(0deg, #01cdfb 100%, #01cdfb 100%) !important;
  }

  &:active {
    color: #01cdfb !important;
    .gradientAnimation(0deg, #01acec 100%, #01acec 100%) !important;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;