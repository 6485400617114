.retailer-user-managment-page-confirmation {
  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    align-items: center;

    .ant-form-item-control-input {
      margin-right: 8px;

      input {
        border: 1px solid #e4e4e4;
        border-radius: 5px;
        width: 300px;
      }
    }

    &__submit {
     
      width: 155px;
      height: 32px;
      background: #01cdfb;
      border-radius: 5px;
      color: #222222;
      border: none;
      font-weight: 600;
    }
  }

  .users-table {
    max-width: 100%;

    table {
      max-width: 100%;
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;