.ticket-details-wrapper {
  background-color: #f8f8f8;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: -10px;

  strong {
    margin-right: 5px;
  }

  .ticket-detail-item {
    display: flex;
    align-items: center;
  }
}

.text-ellipsis {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;