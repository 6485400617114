.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 1001;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.pickupRow--preview {
  background-color: #ffe66c85;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;