.info-wrapper {
  display: grid;
  padding-right: 16px;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Foreground/medium-emphasis */

    color: #6f6f6f;
  }

  &__info {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    word-break: break-word;
    /* identical to box height, or 200% */

    display: flex;
    align-items: center;

    /* Foreground/high-emphasis */

    color: #000000;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;