.forgotPasswordTitle {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  margin-bottom: 0 !important;
  color: #0a0a0b !important;
}

.forgotPasswordForm {
  margin-top: 33px;
}

.formInput {
  border: none;
  border-bottom: 1px solid #e9e9e9;
  padding-left: 0 !important;
  width: 70%;

  &[class*="ant-input-affix-wrapper-focused"] {
    border-color: none;
    box-shadow: none;
  }

  &[class*="ant-input-affix-wrapper-status-error"] {
    box-shadow: none !important;
    border-color: #e90046 !important;

    & input[class="ant-input"] {
      color: #e90046;
    }

    & span[class="ant-input-prefix"] svg {
      stroke: #e90046;
    }

    & span[class="ant-input-suffix"] svg {
      fill: #e90046;
    }
  }

  & input[class="ant-input"]::placeholder {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: rgba(35, 35, 35, 0.4);
    transition: all 250ms linear;
  }

  & span[class="ant-input-prefix"] {
    margin-right: 16px;

    & svg {
      transition: all 250ms linear;
      stroke: #01cdfb;
    }
  }

  & span[class="ant-input-suffix"] svg {
    transition: all 250ms linear;
    fill: #e9e9e9;
  }

  &:not([class*="ant-input-affix-wrapper-status-error"]):hover {
    input[class="ant-input"]::placeholder {
      color: #01cdfb;
    }

    & span[class="ant-input-suffix"] svg {
      fill: #01cdfb;
    }
  }
}
.formInputMobile {
  &:extend(.formInput);
  width: 100% !important;
  height: 50px;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  & input {
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 150% !important;
  }
}

.formItem {
  div[class="ant-form-item-explain-error"] {
    color: #e90046 !important;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.submitButton {
  margin-top: 40px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  text-align: center !important;
  color: #0a0a0b !important;
}

.forgotPasswordTitleMobile {
  &:extend(.forgotPasswordTitle);
  margin-left: 0;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 120% !important;
}
.forgotPasswordMainTitleMobile {
  margin-left: 0;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 120% !important;
  .forgotPasswordMainTitleYellowMobile {
    &:extend(.forgotPasswordMainTitleMobile);
    color: #01cdfb !important;
  }
}

.modalTitle {
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: rgb(62, 166, 252) !important;
  text-align: left;
  margin-bottom: 32px !important;
}

.modalSubTitle {
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: rgba(10, 10, 11, 1) !important;
}

.modalDescription {
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  text-align: left !important;
  color: #565656 !important;
  margin-bottom: 24px !important;
}
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;