.retailerCabinetHeader {
  position: fixed;
  z-index: 10;
  background: #ffffff;
  width: 100vw;
  padding: 15px;
  box-shadow: 6px 1px 6px 1px #e4e4e4f1;
  @media (min-width: 768px) {
    padding: 0 50px;
  }
}
.smallScreenHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: none;
  }
}
.fullScreenHeader {
  display: none;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-menu {
    background-color: transparent;
    svg {
      width: 40px !important;
      cursor: pointer;
    }
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
    align-self: baseline;

    svg {
      width: 100px !important;
      cursor: pointer;
    }

    .language-select-item {
      margin-top: 5px;
      margin-left: 30px;
    }
  }

  .ant-menu-item-selected {
    padding-bottom: 2px !important;
  }
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.menuItemStyle {
  font-size: 14px;
  color: rgba(34, 34, 34, 0.5);
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;