@import "../../../../utils/styleMixins";

.signUpWrap {
  position: fixed;
  height: auto;
  left:0; 
  top: 0; 
  bottom: 0;
  right: 0;
}

.promoSection {
  background: #0e0e0e;
  padding-top: 29px;

  background-image: url("../../../../assets/newLanding/auth/driverSignUpBg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  height: 100vh;
}

.promoSectionTitle {
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 60px !important;
  color: #e9e9e9 !important;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 0 !important;
}

.promoSectionSubtitle {
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 60px !important;
  color: #01cdfb !important;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 0 !important;
}

.mainSection {
  padding-top: 29px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.ligInButton {
  border-radius: 48px;
  border: none;
  transition: all 250ms linear;
  background: transparent;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #01cdfb;
  display: flex;
  align-items: stretch;

  .gradientAnimation(124.01deg, #01cdfb 19%, #21b9ff 86.68%);
  background-image: linear-gradient(transparent, transparent);

  &:hover {
    cursor: pointer;
    backdrop-filter: blur(16px);
    color: #0a0a0b;
  }
}

.modalTitle {
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: rgb(62, 166, 252) !important;
  text-align: left;
  margin-bottom: 32px !important;
}

.modalSubTitle {
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: rgba(10, 10, 11, 1) !important;
}

.modalDescription {
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  text-align: left !important;
  color: #565656 !important;
  margin-bottom: 24px !important;
}
.signUpButton {
  margin-top: 48px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #0a0a0b;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;