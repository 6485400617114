.button {
  width: 157px;
  height: 48px;
  padding: 12px 20px;
  font-style: normal;
  background: transparent;
  transition: all 250ms linear;
  background: transparent;
  border-radius: 40px;
  line-height: 150%;
  border: 1px solid #2b2b2b;

  .loginText {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    margin-right: 8px;
  }

  .logIn {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #01cdfb;
  }

  &:hover {
    background: linear-gradient(124.01deg, #01cdfb 19%, #21cbff 86.68%);
    .logIn {
      color: #0a0a0b !important;
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;