.trackingInfoHeader {
  background: #ffffff;
  padding: 15px;
  @media (min-width: 768px) {
    padding: 0 50px;
  }
}
.smallScreenHeader {
  @media (min-width: 768px) {
    display: none;
  }
}
.fullScreenHeader {
  display: none;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-top: 15px;
      width: 100px;
    }
  }
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.menuItemStyle {
  font-weight: normal;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.5);
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;