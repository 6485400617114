@import "../../variables.less";

.pageTitle {
  .pageTitle;
}

.ordersManegmentWrapper {
  .adminPanelPageWrapper;
}

.spinner {
  .spinnerStyle;
}

.mobile-page-wrapper--orders-managment {
  .upload-orders-block {
    padding-top: 16px;
    border-top: 1px solid rgba(1, 21, 40, 0.05);
    border-bottom: 1px solid rgba(1, 21, 40, 0.05);

    .uploadOrdersDraggerWrapper {
      justify-content: center;

      .uploadText {
        margin-left: 12px;
      }
    }

    &__btn-group {
      display: flex;
      margin-top: 16px;
      margin-bottom: 8px;

      .mobile-btn {
        margin-bottom: 8px;

        &--blue {
          margin-right: 16px;

          a,
          svg {
            color: #ffffff;
          }

          svg {
            margin-right: 8px;
          }
        }

        &--purple {
          svg {
            margin-right: 8px;
          }
        }
      }

      @media (max-width: 374px) {
        flex-direction: column;
      }
    }
  }

  .ant-tabs {
    padding-top: 16px;

    &-tab {
      padding-top: 0;
      padding-bottom: 16px;
      font-family: SFProText;
      font-size: 12px;
      line-height: 14px;
      color: #011528 !important;
      letter-spacing: 0.6px;

      &-active > div {
        color: #011528 !important;
        font-weight: 600 !important;
      }
    }
  }

  .mobile-search {
    margin-bottom: 12px;
  }

  .table-filters {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 16px;

    .ant-checkbox-wrapper {
      color: #1f85de;

      .ant-checkbox-inner {
        background-color: transparent;
      }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #1f85de;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1f85de !important;
      border-color: #1f85de;
    }

    .ant-checkbox-checked {
      &::after {
        border: 1px solid #1f85de !important;
      }
    }

    .custom-select {
      margin-top: 0px;
    }

    &__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    &__middle {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 12px;
    }

    &__bottom {
      width: 100%;
      display: flex;
      align-items: flex-end !important;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .mobile-collapse-list__panel-header {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1f85de !important;
      border-color: #1f85de;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #1f85de;
    }

    .ant-checkbox-checked {
      &::after {
        border: 1px solid #1f85de !important;
      }
    }
  }

  .rows-wrapper {
    .row {
      &:last-child {
        border-bottom: 1px solid rgba(1, 21, 40, 0.05) !important;
      }

      .notes {
        padding-left: 4px;
        padding-right: 9px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .download-barcode {
    border-radius: 50%;
    background-color: #01cdfb;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;

    svg {
      color: #222222;
      font-size: 14px;
    }
  }

  .btn-actions {
    padding: 16px 24px 0px 24px;

    .mobile-btn--gray {
      margin-top: 0;
    }

    button {
      margin-bottom: 20px;
    }

    &__group {
      display: flex;

      button {
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;