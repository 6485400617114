
@import '../../../../variables.less';

.title {
    .registrationStepsTitle;
}
.personalCabinetButton {
    background: #01cdfb;
    box-shadow: 0px 10px 30px rgba(252, 220, 62, 0.4);
    border-radius: 40px;
    color: #222222;
    height: auto;;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border-color: #01cdfb;
    padding: 0 !important;

}
.personalCabinetButton>span {
    padding: 16px 32px;
}

.retailerRegistrationFinishedImagesWrapper {
     display: flex;
     justify-content: space-between;
     width: 370px;
}
.registrationFinishedText {
    width:100%;
    max-width: 370px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(34, 34, 34, 0.5);
    margin-bottom: 32px;
    }
.finishImage {
    margin: 32px 0;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;