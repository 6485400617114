.cfr-actions-container {
  display: flex;
  justify-content: space-between;

  .cfr-actions-right {
    display: flex;

    .cfr-retailer-select {
      margin-right: 20px;

      label {
        margin-right: 10px;
      }
    }
  }
}

.cfr-order-table {
  margin-top: 20px;
}

.retailer-select {
  width: 200;
  padding-left: 10;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;