@import "../../../variables.less";

.cfr-settings-container {
  padding: 0 20px 20px;

  .cfr-settings-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .header-left {
      display: inline-flex;
      align-items: center;

      .header-title {
        margin: 0;
      }
    }
  }

  .cfr-settings-wrapper {
    display: flex;
    flex-direction: column;

    .cfr-settings-row {
      display: flex;
      justify-content: space-between;
    }
  }

  .cfr-action-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    .run-cfr-btn {
      margin-left: 20px;
    }
  }

  .cfr-zone-switch {
    display: flex;

    label {
      margin-right: 10px;
    }
    .ant-switch-checked {
      background-color: @success-color;
    }
  }

  .cfr-setting {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;

    &:nth-child(1) {
      padding-right: 20px;
    }

    &:nth-child(2) {
      padding-left: 20px;
    }

    .params-input-with-addon,
    .params-input,
    .ant-picker-range {
      width: 100%;
    }

    label {
      padding-top: 0px;
      font-weight: 500;
      margin-right: 10px;
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;