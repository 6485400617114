@import '../../../../variables.less';

.formStyle {
  .registrationFormStyle;
}

.shareLocationRetailerBtn {
  box-shadow: 0px 10px 30px rgba(252, 220, 62, 0.4);
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  height: 48px;
  width: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addressRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;