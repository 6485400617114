@import "../../variables.less";

.retailerCabinetContent {
  background-color: #fff;
  border-radius: 12px;
  padding: 25px;
  .contentStyle .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .ant-tabs-tab-active {
    color: black;
    .ant-tabs-tab-btn {
      font-weight: bolder;
      // font-size: larger;
      color: black;
      text-shadow: 0 0 0.25px currentColor;
    }
  }
  &-home-tabs > .ant-tabs-nav {
    .ant-tabs-tab-active {
      color: black;
      .ant-tabs-tab-btn {
        font-weight: bolder;
        font-size: larger;
      }
    }
  }
}

.retailerDashboardContainer {
  h2 {
    padding: 30px 0px;
    font-weight: bold;
  }
  .retailerDashboardFlex {
    display: grid;
    flex-wrap: wrap;
    height: 100%;
    flex-grow: 1;
    overflow-x: auto;
    align-items: flex-start;
    @media (min-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      .openApi {
        grid-row: 3 / span 2;
      }
    }
    @media (min-width: 1140px) {
      grid-template-columns: repeat(3, 1fr);
      .openApi {
        grid-row: 2 / span 2;
      }
    }
    @media (min-width: 1600px) {
      grid-template-columns: repeat(4, 1fr);
      .openApi {
        grid-row: 1 / span 2;
      }
    }
    @media (min-width: 1940px) {
      grid-template-columns: repeat(5, 1fr);
      .openApi {
        grid-row: 1 / span 2;
      }
    }
    @media (min-width: 2300px) {
      grid-template-columns: repeat(6, 1fr);
      .openApi {
        grid-row: 1 / span 2;
      }
    }
    // gap: 24px;
    .statBox {
      h3 {
        font-weight: bold;
        margin: 0;
      }
      .svg-container {
        min-width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      div {
        margin-left: 25px;
      }
      margin-right: 25px;
      margin-bottom: 25px;
      display: flex;
      padding: 0px 10px;
      // gap: 25px;
      align-items: center;
      flex-wrap: nowrap;
      min-width: 315px;
      min-height: 80px;
      background-color: rgba(0, 132, 255, 0.2);
      border-radius: 10px;
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;