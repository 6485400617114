.trackingInfoPageWrapper {
  max-width: 1280px;
  width: 100%;
  margin: auto;

  .nav-tabs-suptitle {
    font-family: SFProText;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    padding-left: 26px;
    color: rgba(34, 34, 34, 0.3);
    margin-bottom: 20px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .ant-tabs-nav {
    border-left: 1px solid rgba(0, 0, 0, 0.05);

    @media (max-width: 1024px) {
      border-left: none;
    }
  }

  .ant-tabs-nav-list {
    @media (max-width: 1024px) {
      align-items: flex-start;
    }
  }
  .ant-tabs-tab {
    width: 100%;
  }
}

.customLocationBtn {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.signUpFormTitle {
  font-family: SFProText;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 34px;
  color: #222222;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 12px;
  letter-spacing: 1.4px;

  @media (max-width: 1024px) {
    margin-top: 10px;
  }

  @media (max-width: 515px) {
    width: 220px;
  }
}

.supportIconWrapper {
  position: fixed;
  bottom: 15px;
  
  right: 10px;
}

.shareLocationWrapper {
  &.locationNotShared {
    margin-bottom: 20px;
    @media (min-width: 700px) {
      margin-bottom: initial;
    }
  }
}

.trackingInfoHomeWrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.trackingNumberDate {
  display: flex;
  width: 100%;
  max-width: 680px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 43px;

  @media (max-width: 575px) {
    justify-content: center;
  }
}

.supportButtonWrapper {
  padding: 5px;
  width: 94px;
  height: 94px;
  border-radius: 45px;
  //border: 2px solid #01cdfb;
  align-self: self-end;

  button {
    border-radius: 50%;
  }
}

.tabButton {
  max-width: 170px;
  display: flex;

  .tabInfo {
    margin-left: 8px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    div:first-child {
      display: flex;
    }

    svg {
      margin-left: 5px;
    }
  }

  .tabAddress {
    max-width: 100%;
    text-align: left;
    white-space: initial;
    overflow: hidden;
    font-size: 12px;
    word-wrap: break-word;
    color: rgba(34, 34, 34, 0.5);
  }
}

.additionalFunctionalityButtonsContainer {
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
}

.packageContentContainer {
  display: flex;
  @media (max-width: 1310px) {
    flex-direction: column;
  }

  .packageMainContent {
    margin-right: 40px;

    &.locationNotShared {
      @media (min-width: 1024px) {
        order: initial;
      }
    }
  }
}

.thank-for-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .finishImage {
    width: 270px;
  }
}

.usePreviousLocationModalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .usePreviousLocationModal {
    display: flex;

    background-color: white;
    top: 30%;
    left: 50%;
    border: 1px solid #f0f0f0;
    padding: 25px;
    flex-direction: column;
    border-radius: 5px;

    .modalText {
      padding: 0 2px 5px;
    }

    .buttonBox {
      display: inherit;
      justify-content: space-around;
    }
  }
}

.usePreviousLocationModalWrapperHidden {
  display: none;
}

.packageShareLocationContent {
  &--top {
    @media (max-width: 1310px) {
      order: -1;
      margin-bottom: 20px;
    }
  }
}

.order-type-icon {
  width: 20%;
  margin-left: 6%;
  display: flex;
  align-items: center;
  flex-direction: column;
  strong {
    color: rgb(177, 1, 1);
    font-weight: bolder;
  }
  svg {
    width: 12px;
    height: 12px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;