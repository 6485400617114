.trackingInfoContent {
  min-height: initial !important;
  height: initial !important;

  .mapWrapper {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 2px solid rgba(34, 34, 34, 0.1);
  }
}

.map-actions {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  button {
    box-sizing: border-box;
    width: 100px;
    height: 50px;
    border-radius: 5px;
    font-size: 1.2rem;

    &:first-child {
      margin-right: 10px;
    }
  }
  @media (max-width: 600px) {
    // flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    button {
      // width: 100% !important;
      flex-grow: 1;
      margin: 0px !important;
      opacity: 0.7 !important;
    }
  }
}

.findMe {
  position: absolute;
  right: 0;
  bottom: 20%;

  button {
    width: 40px;
    height: 40px;
    margin-right: 9px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.currentLocation {
  width: 10px;
  height: 10px;
  background-color: #008099;
  border-radius: 50%;
  border: 1px solid black;
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.chosenAddressClient {
  width: 85%;
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  margin-top: 8px;
  position: absolute;
  // font-weight: bold;
  // font-size: 20px;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: lightgrey 1px 1px 7px;
  gap: 8px;

  &-green {
    svg {
      fill: green;
      margin-right: 8px;
    }
  }
  &-gray {
    svg {
      fill: gray;
      margin-right: 8px;
    }
  }
  &-red {
    svg {
      fill: red;
      margin-right: 8px;
    }
  }

  .ant-input-search-button {
    svg {
      margin: auto;
    }
  }
  .autocomplete-dropdown-container {
    padding: 5px;
    padding-bottom: 2px;
    border-radius: 5px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;