@import "../../../../variables.less";

.preview_wrapper {
  padding: 5px;
  &--title,
  &--header {
    font-size: medium;
    b {
      margin-left: 5px;
    }
  }

  &--important,
  &--confirm,
  &--note {
    margin-left: 10px;
    .anticon {
      margin-right: 5px;
    }
  }
  &--important {
    color: @danger-color;
    font-weight: 600;
  }

  &--info {
    color: @success-color;
    font-size: small;
  }

  &--note {
    color: @link-color;
    font-size: small;
    font-weight: 600;
  }

  &--actions {
    justify-content: flex-end;
    .ant-btn {
      margin-right: 5px;
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;