@import "../../../variables.less";

.cfr-results-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  .message-output-wrapper {
    width: 350px;
  }

  .error-message {
    background-color: #f4f4f4;
    padding: 10px;
    text-align: center;
  }

  .cfr-results-actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    .view-job-btn {
      margin-left: 20px;
    }
  }

  .success-header,
  .error-header,
  .partial-success-header {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      margin: 20px 0 40px 0;
    }

    .icon {
      font-size: 50px;
    }
  }

  .success-icon,
  .success-title,
  .messages-output {
    display: flex;
    justify-content: center;
  }

  .success-header .icon,
  .success-header .title {
    color: @success-color;
  }
  .error-header .icon,
  .error-header .title {
    color: @danger-color;
  }
  .partial-success-header .icon,
  .partial-success-header .title {
    color: @dark-yellow;
  }
}

.messages-output {
  width: 100%;
  flex-direction: column;

  .messages-output-row {
    display: flex;
    justify-content: space-between;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;