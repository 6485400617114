@import "../../../../../variables.less";

.retailerInfoTitle {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: @font-color;
  padding-bottom: 10px;
}

.retailerInfoWrapper {
  padding: 20px;
}

.retailerInfoItem {
  margin: 0;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: @font-color;
}

.retailerInfoItem > span {
  font-weight: normal;
}

.noData {
  color: @font-grey-color;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;