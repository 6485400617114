/*SideBar*/
.css-dip3t8 {
    background-color: rgb(249, 249, 249) !important;
}


.mainDashBoard {
    position: fixed;
    top: 8%;
    width: 85%;
    max-width: 85%;
    margin: auto;
    display: flex;
    margin-left: 14%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.datePickerSuper {
    width: 20%;
    margin: 1%;
}

.cardSuperContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 2%;
    align-items: flex-start;



}

.cardSuper {
    border: 1px solid;
    width: 20%;
    height: 120px;
    padding: 1%;
    margin: 1%;
    cursor: pointer;

    border-radius: 10px;

}

.filtersLine {
    display: flex;
    width: 100%;
    align-items: center;
}




/* Drivers Page */
.superAdminTables {
    width: 100%;
    margin-top: 2%;

}

.editmarkerDriver {
    margin-bottom: 4%;
}

.editDriverEmail {
    display: flex;
    flex-direction: row;
    gap: 1%;
    align-items: center;
    cursor: pointer;
}


.allDriversSettings {
    display: flex !important;
    flex-direction: column !important;
    width: 80%;
    margin: -28% 10% auto;
}


.companiesList {
    margin-bottom: 0;
    width: 20%;

    font-size: small;

}

.filters {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.searchBox {
    width: 20%;
}