@import '../../../variables.less';
.nextStepButton {
    background: @primary-color;
    box-shadow: 0px 10px 30px rgba(81, 228, 251, 0.4);
    border-radius: 40px;
    width:125px;
    color: @font-color;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 0;
    height: auto;
    @media (min-width: 1024px) {
        width:145px;
      }
}

.nextStepButton >span {
    padding:15px 16px;
    font-weight: bold;
    font-size: 14px;
    color: @font-color;
}

.previousStepWrapper {
    display: flex;
    align-items: center;
}
.previousStepButton {
    color: @font-color;
    padding-left: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: @font-color;
    @media (min-width: 1024px) {
       margin-right: 300px;
      }
}
.stepActionsButtons {
    display: flex;
    margin-top: 81px;
}
.stepActionsRow {
    display: flex;
    justify-content: space-between;
    width:100%;
    @media (min-width: 1024px) {
        width: fit-content;
       }
}
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;