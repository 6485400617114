.header {
  font-weight: 300 !important;
  font-size: 32px !important;
  line-height: 120% !important;
  color: #e9e9e9 !important;
}
.headerSubtitle {
  color: #01cdfb !important;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 120% !important;
  margin-bottom: 24px !important;
}
.headerMessage {
  max-width: 524px;
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #e9e9e9;
  &Mobile {
    max-width: 264px;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #e9e9e9;
  }
}
.tableMessage {
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: #e9e9e9;
}
.simpleRow {
  margin-bottom: 12px;
  div {
    padding-top: 12px;
  }
}
.row {
  margin-bottom: 12px;

  div {
    padding-top: 12px;
  }
  &::before {
    content: " ";
    display: block;
    height: 1px;
    border-bottom: 1px solid grey;
    width: 900px;
  }
}
.hideScroll {
  &::-webkit-scrollbar {
    display: none;
  }
}
.simpleRowScroll {
  margin-bottom: 12px;
  min-width: 430px;
  justify-content: space-between;

  div {
    padding-top: 12px;
    padding-right: 20px;
  }
}
.rowScroll {
  margin-bottom: 12px;
  min-width: 430px;
  justify-content: space-between;
  div {
    padding-top: 12px;
    padding-right: 20px;
  }
  &::before {
    content: " ";
    display: block;
    height: 1px;
    border-bottom: 1px solid grey;
    width: 900px;
  }
}

.termsConditionsPage {
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #e9e9e9;
  }
  div[class="ant-collapse-header"] {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 120% !important;
    color: #e9e9e9 !important;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 10px;
    svg {
      transform: rotate(90deg) scale(1.5) !important;
      fill: #2b2b2b !important;
    }
  }
  div[class="ant-collapse-header"][aria-expanded="true"] {
    color: #01cdfb !important;
    svg {
      transform: rotate(-90deg) scale(1.5) !important;
      fill: #01cdfb !important;
    }
  }
  div[class~="ant-collapse"] {
    background: none !important;
    border: none !important;
  }
  div[class~="ant-collapse-item"] {
    background: none !important;
    border: none !important;
  }
  div[class~="ant-collapse-content"] {
    background: none !important;
    border: none !important;
  }
}

// mobile

.headerMessageMobile {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #e9e9e9;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;