@import "../../../../../variables.less";

.additionalInfoTitle {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: @font-color;
  padding-bottom: 10px;
}

.additionalInfoWrapper {
  padding: 20px;

  .additionalInfoItem {
    margin: 0;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    color: @font-color;
  }

  .additionalInfoItem > span {
    font-weight: normal;
    font-family: SFProText;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;