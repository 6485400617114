@import '../../variables.less';

.ant-layout {
    background: #fafafa;
}

.trackingInfoContent {
    .contentStyle;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;