.signUpTitle {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: #0a0a0b !important;
  margin-bottom: 0 !important;
}

.signUpSubtitle {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: #01cdfb !important;
  margin-bottom: 0 !important;
}

.signUpForm {
  margin-top: 33px;
  width: 300px;
}

.formInput {
  border: none;
  border-bottom: 1px solid #e9e9e9;
  padding-left: 0 !important;
  width: 300px;

  &[class*="ant-input-affix-wrapper-focused"] {
    border-color: none;
    box-shadow: none;
  }

  &[class*="ant-input-affix-wrapper-status-error"] {
    box-shadow: none !important;
    border-color: #e90046 !important;

    & input[class="ant-input"] {
      color: #e90046;
    }

    & span[class="ant-input-prefix"] svg {
      stroke: #e90046;
    }

    & span[class="ant-input-suffix"] svg {
      fill: #e90046;
    }
  }

  & input[class="ant-input"]::placeholder {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: rgba(35, 35, 35, 0.4);
    transition: all 250ms linear;
  }

  & span[class="ant-input-prefix"] {
    margin-right: 16px;

    & svg {
      transition: all 250ms linear;
      stroke: #01cdfb;
    }
  }

  & span[class="ant-input-suffix"] svg {
    transition: all 250ms linear;
    fill: #e9e9e9;
  }

  &:not([class*="ant-input-affix-wrapper-status-error"]):hover {
    input[class="ant-input"]::placeholder {
      color: #01cdfb;
    }

    & span[class="ant-input-suffix"] svg {
      fill: #01cdfb;
    }
  }
}

.formItem {
  div[class="ant-form-item-explain-error"] {
    color: #e90046 !important;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.signUpButton {
  margin-top: 48px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #0a0a0b;
  width: 300px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;