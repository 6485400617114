@import "../../variables.less";

.title {
  padding: 0 15px;
  margin-top: 30px;
}

.job-actions-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin: 20px 0;

  .left-Job-actions {
    display: flex;
    align-items: center;
  }
}

.search-jobs {
  width: 200px;
  margin-right: 20px;
}

.jobs-table-wrapper {
  .ordersTablewrapper {
    .ant-table-container {
      &:before,
      &:after {
        box-shadow: none;
      }

      .ant-table-content {
        overflow-x: unset;

        @media (max-width: 1045px) {
          overflow-x: auto;
        }
      }
    }
  }
}

.mobile-page-wrapper--jobs-managment {
  .table-item {
    .rows-wrapper {
      padding: 12px 24px;

      .row {
        padding: 12px 0 8px;
      }
    }
  }

  .expand {
    &-body-item {
      margin-bottom: 12px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .rows-wrapper {
        padding: 0 0 12px 0;

        .row {
          padding: 8px 0 8px !important;
          flex-wrap: wrap;
          line-break: anywhere;

          &__value {
            line-height: 20px;
          }
        }
      }
    }
  }
}

.valid-job-select,
.invalid-job-count {
  margin-right: 10px;
}

.invalid-job-count {
  font-weight: bold;
}

.job-actions {
  display: flex;
  align-items: center;

  button {
    margin-right: 20px;
  }
}

.round-btn {
  margin-right: 10px;
  .anticon {
    svg path {
      fill: #000;
    }
  }

  color: #000;
  margin-right: 10px;
  &:hover {
    color: #000;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;