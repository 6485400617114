.jobs_wrapper {
  padding: 20px 10px;
  &--backBtn {
    align-items: baseline;
    margin: 10px 7px;
    .ant-btn-round.ant-btn-icon-only {
      width: 38px;
      height: 38px;
      padding: 2px;
    }
    &-label {
      font-weight: 500;
      margin-left: 5px;
      font-size: 15px;
    }
  }
  &--main,
  &--compare {
    padding: unset;
  }
}

.jobHandler {
  &--toolbar {
    justify-content: flex-end;
    align-items: baseline;
  }
  &--pickup_wrapper {
    padding: 1rem;
    h4 {
      font-weight: 600;
    }
  }
}

.footer {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 86%;
  box-shadow: 7px -1px 7px 1px #bcbcb163;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;