.admin-container {
  width: 80%;
  background-color: #fff;
  padding: 20px;
  margin-top: 40px;
  margin-left: 20px;

  .ticket-item {
    display: flex;
    border: 1px solid rgb(196, 196, 196);
    height: 40px;
    border-radius: 4px;
    align-items: center;
    padding: 0 20px;
    margin: 0 20px;

    .ticket-item-right,
    .ticket-item-left {
      display: flex;
      justify-content: space-between;
      flex: 0 0 25;

      span {
        margin: 0 20px;
      }
    }
  }
}

.filter-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .ticket-filter-select {
    width: 150px;
    margin-left: 10px;
  }
}

.text-ellipsis {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;