@import "../../../variables.less";

.page-wrapper-price-settings {
  padding: 30px;
  height: auto;
  min-height: 100vh;
  position: relative;
  background-color: @page-wrapper-bg;

  .content-block-price-settings {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @content-block-bg;
  }
}

.mobile-page-wrapper {
  padding: 15px 10px;
  
  .content-block-price-settings {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.no-border-style {
  border: none;
}
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;