.honeycombWrap {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 88px;
}

.honeycomb {
  fill: #0d0d0d;
  transition: all 250ms linear;
  position: absolute;
  z-index: 2;

  &:hover {
    fill: #01cdfb;
    filter: drop-shadow(0px 0px 57px rgba(62, 223, 252, 0.7));
    z-index: 1;
  }
}

.honeycomb1:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  top: 70px;
  right: -90px;
}

.honeycomb2:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 180px;
  height: 175px;
  top: 100px;
  right: 120px;
}

.honeycomb3:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 205px;
  height: 200px;
  top: 244px;
  right: 30px;
}

.honeycomb4:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  top: 739px;
  left: 115px;
}

.honeycomb5:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 180px;
  height: 175px;
  top: 934px;
  left: 220px;
}

.honeycomb6:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 205px;
  height: 200px;
  top: 921px;
  left: 28px;
}

.honeycomb7:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  top: 2096px;
  right: -36px;
}

.honeycomb8:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 205px;
  height: 200px;
  top: 2277px;
  right: 75px;
}

.honeycomb9:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 180px;
  height: 175px;
  top: 2444px;
  right: 2px;
}

.honeycomb10:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 160px;
  height: 150px;
  top: 2452px;
  right: 170px;
}

.honeycomb11:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  top: 2742px;
  left: 40px;
}

.honeycomb12:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  top: 4379px;
  right: -70px;
}

.honeycomb13:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 190px;
  height: 185px;
  top: 4402px;
  right: 140px;
}

.honeycomb14:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 190px;
  height: 185px;
  top: 4554px;
  right: 55px;
}

.honeycomb15:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 160px;
  height: 150px;
  top: 4790px;
  left: 0px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;