.filtersOptions__row {
  margin: 5px 0px;
  justify-content: space-between;
  &--label {
    font-weight: 600;
    margin-right: 7px;
  }
  &--status {
    min-width: 200px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;