@import "../../../variables.less";

.pageTitle {
  .pageTitle;
}

.retailerHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.retailerTokens {
  display: flex;
  justify-content: space-between;
}

.downloadUploadWrapper {
  display: flex;
  align-items: center;

  .uploadOrdersWrapper,
  button {
    margin-right: 30px;
  }
}

.uploadOrdersWrapper {
  width: 100%;
  max-width: 200px;
}

.downloadTemplateExample {
  font-weight: bold;
  height: 56px;
  border-radius: 10px;
}

.error {
  color: #db2269;
  font-size: 0.825em;
  display: relative;
}

.errorMessageLine {
  text-align: left;
}

.errorMessageHolderTitle {
  font-weight: 700;
}

.reportModalSelect {
  width: 160px;
  margin-left: 5px;
}

.uploadManuallyWrapper {
  height: 56px;
  background: rgba(252, 220, 62, 0.05) !important;
  border: 2px dashed #01cdfb !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;

  p {
    margin-bottom: 0;
  }
}

.upload-orders-modal {
  min-height: 40vh;
  width: 1240px !important;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -webkit-moz-appearance: textfield;
  }
  .react-tel-input {
    input {
      width: 200px;
      height: 32px;
    }
  }
  .ant-select-selection-item {
    max-width: 80px;
    text-overflow: ellipsis;
  }

  .labels-row,
  .ant-form-item-no-colon {
    display: flex;
    margin-bottom: 8px;
    color: grey;
    font-weight: 500;

    &__1 {
      margin-right: 11px;
      &_2 {
        margin-left: 11px;
        margin-right: 50px;
      }
    }

    &__2 {
      margin-left: 30px;
      margin-right: 50px;
      width: 100px;
      &_2 {
        width: 150px;
        margin-right: 52px;
      }
    }

    &__3 {
      width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 1px;
    }

    &__4 {
      width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 8px;
    }

    &__5 {
      margin-right: 50px;
      margin-left: 11px;
    }

    &__6 {
      margin-right: 60px;
    }

    &__7 {
      margin-right: 27px;
    }

    &__8 {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 6px;
    }
    &__8_2 {
      margin-right: 71px;
    }

    &__9 {
      margin-right: 45px;
    }
    &__9_2 {
      margin-right: 88px !important;
    }

    &__10 {
      margin-right: 40px;
    }
    &__10_2 {
      margin-right: 97px !important;
    }

    &--store {
      .labels-row__1 {
        margin-right: 104px !important;
      }

      .labels-row__9 {
        margin-right: 136px;
      }

      .labels-row__10 {
        margin-right: 146px;
      }

      .labels-row__11 {
        margin-right: 127px;
      }
    }
  }

  .form-row {
    display: flex;
    align-items: center;

    .anticon-minus-circle {
      margin-left: 12px;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-explain {
    min-height: 0px !important;
  }

  .add-order {
    margin-top: 16px;
    width: 100%;
  }

  .delivery-radio-group {
    margin-bottom: 20px;
  }

  .delivery-points-select {
    display: flex;

    .ant-select-selector {
      width: 200px;
    }
  }
}

.filtersWrapper {
  justify-content: space-between;
  margin-bottom: 10px;
  &__title {
    .anticon {
      font-size: medium;
      svg path {
        fill: @secondary-color;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      margin-left: 10px;
      margin-bottom: -3px;
    }
  }
  &__actionBar {
    .tableSelector {
      min-width: 25%;
      margin-right: 10px;
    }
    .searchOrderInput {
      margin-right: 10px;
      min-width: 200px;

      input {
        margin-left: 0 !important;
      }
    }
    &--filterBtn {
      .anticon {
        margin-right: 5px;
        svg path {
          fill: #000;
        }
      }

      color: #000;
      margin-right: 10px;
      &:hover {
        color: #000;
      }
    }
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.headerBar__addOrderBtn {
  margin-right: 7px;
  .anticon {
    margin-right: 7px;

    svg path {
      fill: #000;
    }
  }
  color: #000;
  font-weight: 700;

  &:hover {
    color: #000;
  }
}
.FilterTag {
  margin: 2px;

  &__wrapper {
    justify-content: baseline;
    display: inline-flex;
  }
  &__name {
    padding-right: 5px;
  }
  &__name::after {
    content: ": ";
  }
  &__item:not(:last-child)::after {
    content: " ,";
  }
  &__item {
    padding-right: 5px;

    margin-bottom: 0px !important;
  }
}

.order-modal {
  &__section-title {
    font-weight: 700;
    font-size: 16px;
  }
  &__select {
    width: 150px !important;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;