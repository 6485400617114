@import "../../../variables";

.customerInfo {
  color: @font-color;
  font-weight: bold;
  font-size: 12px;
}
.customerInfo > p > span {
  font-weight: normal;
}
.customer-marker {
  font-size: 18px;
  &-wrap {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;