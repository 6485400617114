.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: #ffffff;
  padding: 15px 30px !important;
  display: flex;
  align-items: center;
}

.ant-menu-item-selected {
  color: rgba(0, 0, 0, 0.65) !important;
  border-bottom: none !important;
}

.siderStyle {
  border-radius: 0 6px 6px 0;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  overflow-x: hidden;
  z-index: 50;

  .ant-menu-item {
    width: 110% !important;
    padding-left: 14px !important;

    .ant-badge-count {
      top: 7px;
    }
  }

  .ant-menu-item-selected {
    color: #001628 !important;
  }

  ul:not(.ant-menu-sub) > li {
    &:not(.ant-menu-submenu-open) {
      display: flex;
      align-items: center;
    }

    a {
      display: inline-block;
      width: 125px;
      color: rgba(255, 255, 255, 0.65);
    }

    &.ant-menu-item-selected {
      background-color: #001628 !important;

      a,
      svg path {
        color: #01cdfb;
        fill: #01cdfb;
      }

      svg g {
        opacity: 1;
      }
    }

    svg {
      margin-right: 8px;

      path {
        fill: rgba(255, 255, 255, 0.5);
      }

      g {
        opacity: 1;
      }
    }
  }
}

.header-logo {
  padding: 30px 20px;
}

.header-selector {
  margin: 16px 0px;
}

.content-wrapper {
  transition: margin 0.1s;
  max-width: calc(100vw - 200px);
  overflow: initial;
  position: relative;
}

.ant-layout.training {
  overflow-y: scroll;
}

.customer_cabinet {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
  position: relative;
  left: 100px;
}

::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f;
}

// Top Header

.header-wrapper {
  width: calc(100% - 200px);
  height: 60px;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  position: fixed;
  z-index: 100;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.content-body-wrapper {
  padding-top: 80px;
  padding-left: 10px;
  padding-right: 10px;
}

.ant-popover.ant-popconfirm.logout-popover-wrapper {
  position: fixed;
}

.crumb-title {
  text-transform: capitalize;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;