@import "../../variables.less";

.retailerContentWrapper {
  margin-top: 70px;
  padding: 12px;
  border-radius: 15px;
}

.siderMenuHolderStyle {
  border-radius: 0 6px 6px 0;
  overflow-y: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  overflow-x: hidden;
  background-color: #ffffff;

  padding-top: 70px;
  border-right: 1px solid #ebebeb;

  .siderCollapseTrigger {
    margin: 10px 12px;
    svg path {
      color: #000;
      fill: #000;
    }
  }
  .ant-menu {
    background-color: #ffffff !important;
    .ant-menu-item .anticon {
      font-size: 18px !important;
    }
  }
  .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.65);

    &:hover {
      color: #001628 !important;
      font-weight: 500;
      svg path {
        fill: #001628 !important;
      }
    }
  }
  .ant-menu-item {
    width: 110% !important;
    .ant-badge-count {
      top: 7px;
    }

    &:hover:not(.ant-menu-item-selected) {
      a {
        color: #001628 !important;
        font-weight: 500;
      }
      svg path {
        fill: #001628 !important;
      }
    }
  }

  .ant-menu-item-selected {
    color: #001628 !important;
  }

  ul > li {
    align-items: center;

    a {
      display: inline-block;
      width: 125px;
      color: rgba(0, 0, 0, 0.65);
    }

    &.ant-menu-item-selected {
      background-color: transparent !important;

      a {
        color: #001628 !important;
        font-weight: 700;
      }
      svg {
        path {
          &:first-child:not(:only-child) {
            color: @bg-light-color;
            fill: @bg-light-color;
          }
          &:nth-child(2),
          &:only-child,
          &:nth-child(3) {
            color: @secondary-color;
            fill: @secondary-color;
          }
        }
      }

      svg g {
        opacity: 1;
      }
    }

    svg {
      margin-right: 8px;

      path {
        fill: rgba(0, 0, 0, 0.5);
        &:only-child,
        &:nth-child(2),
        &:nth-child(3) {
          fill: rgba(0, 0, 0, 0.5);
        }
        &:first-child:not(:only-child) {
          fill: rgba(0, 0, 0, 0.1);
        }
      }

      g {
        opacity: 1;
      }
    }
  }
}

.content {
  transition: margin 0.1s;
  max-width: calc(100vw - 200px);
}

.ant-layout.training {
  overflow-y: scroll;
}

.customer_cabinet {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
  position: relative;
  left: 100px;
}

::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;