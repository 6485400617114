.honeycombWrap {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 170px;
}

.honeycomb {
  fill: #0d0d0d;
  transition: all 250ms linear;
  position: absolute;
  z-index: 2;

  &:hover {
    fill: #01cdfb;
    filter: drop-shadow(0px 0px 57px rgba(35, 204, 255, 0.7));
    z-index: 2;
  }
}

.honeycomb1:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  bottom: 30px;
  left: -70px;
}

.honeycomb2:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 205px;
  height: 200px;
  bottom: 40px;
  left: 140px;
}

.honeycomb3:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 180px;
  height: 175px;
  bottom: 206px;
  left: 65px;
}

.honeycomb4:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 160px;
  height: 150px;
  bottom: 215px;
  left: 235px;
}

.honeycomb5:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  top: 20px;
  right: -90px;
}

.honeycomb6:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 205px;
  height: 200px;
  top: 35px;
  right: 117px;
}

.honeycomb7:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 180px;
  height: 175px;
  top: 197px;
  right: 38px;
}

.honeycomb8:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 160px;
  height: 150px;
  top: 213px;
  right: 203px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;