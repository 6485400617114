@import '../../variables.less';

.retailerRegistrationWrapper {
    display:flex;
}
.retailerInfoTitle {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #222222;
}
.retailerInfoDescription {
    font-size: 16px;
    line-height: 24px;
    color: rgba(34, 34, 34, 0.5);
    margin-top: 24px;
}
.stepTitleDisplaying {
    display: none;
    @media (min-width: 1024px) {
     display: block;
     color: #222222;
    }
}
.stepAdditionalTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #222222;
}
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;