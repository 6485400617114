@import "~antd/dist/antd.less";

@font-face {
  font-family: "SFProText";
  src: local("SFProText"), url(./fonts/SFProDisplay-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "SFProTextBold";
  src: local("SFProText"), url(./fonts/SFProDisplay-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: SFProText;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//** Overrides ant style injection (added only with responsive des)
body[class="ant-scrolling-effect"] {
  width: 100% !important;
}

#root {
  height: 100%;
}

body {
  iframe {
    display: none;
  }
}

.scroll-container {
  overflow: hidden;
}

.scroll-text {
  -moz-animation: marquee-animation 5s linear infinite;
  -webkit-animation: marquee-animation 5s linear infinite;
  animation: marquee-animation 5s linear infinite;
}

@keyframes marquee-animation {
  from {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  33% {
    -moz-transform: translateX(-120%);
    -webkit-transform: translateX(-120%);
    transform: translateX(-120%);
  }

  66% {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  to {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

}

.cls-1 {
  fill: url(#linear-gradient-2);
}

.cls-2 {
  fill: #fff;
}


.cls-mobile{
  fill: #000000;
}

.cls-2-menu{
  fill: rgb(255, 255, 255);

}
.cls-3 {
  fill: url(#linear-gradient-3);
}

.cls-4 {
  fill: url(#linear-gradient);
}

.cls-5 {
  fill: url(#linear-gradient-4);
}

.logoHover{
  display: none;
}

 
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;