@import "../../../../../variables.less";

.orderLink {
  margin: 0;
  color: #008dff;
  cursor: pointer;
}
.actionsColumn {
  display: flex;
  justify-content: center;
}

.forceNextOrder__btn {
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 37px;
}

.configureJob__btn {
  margin-left: 50%;
  transform: translateX(-50%);
}

.ordersTablewrapper > div > div > div {
  margin: 0 !important;
  border: 1px solid lightgray;
}

.orders-notes {
  max-height: "62px";
  overflow: "auto";
  max-width: "105px";
}

.jobTabbleWrapper {
  background-color: lightblue;
}

table tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

table tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;