.layout {
  background: #0e0e0e !important;
  font-family: Mulish, sans-serif;
}

.mainSection {
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.mainSectionMobile {
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  background-image: url("./assets/PolygonYellow.svg"), url("./assets/PolygonLeft.svg"), url("./assets/PolygonTop.svg"),
    url("./assets/PolygonRight.svg");

  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: auto, auto, auto, auto;
  background-position: 0% 90%, right 0% top 136px, left 44px top calc(90% - 115px), left 94px top calc(90% - 28px);
}

.main {
  &:extend(.mainSection);

  display: flex;
  flex-direction: column;
  color: #fff;
}

.secondarySection {
  width: 100vw;
  max-width: 1280px;
  margin: 0 auto;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;