.honeycombWrap {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 88px;
}

.honeycomb {
  fill: #0d0d0d;
  transition: all 250ms linear;
  position: absolute;
  z-index: 2;

  &:hover {
    fill: #01cdfb;
    filter: drop-shadow(0px 0px 57px rgba(7, 218, 255, 0.7));
    z-index: 1;
  }
}

.honeycomb1:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 180px;
  height: 175px;
  top: 772px;
  right: 0px;
}

.honeycomb2:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 160px;
  height: 155px;
  top: 920px;
  right: 90px;
}

.honeycomb3:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 180px;
  height: 175px;
  top: 920px;
  left: 14px;
}

.honeycomb4:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 205px;
  height: 200px;
  top: 1054px;
  left: -90px;
}

.honeycomb5:extend(.honeycomb) {
  &:hover {
    &:extend(.honeycomb:hover);
  }

  width: 160px;
  height: 155px;
  top: 1066px;
  left: 100px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;