@import "../../variables.less";
// .signupWrapper {

.formItem {
  border-top: none;
  border-left: none;
  border-right: none;
  :focus {
    outline: none;
  }
}

.prefixIconYellow {
  color: @primary-color;
}
.adminLoginWrapper {
  margin: auto;
  width: 80%;
  max-width: 400px;
}

.signUpButton {
  width: 100%;
  box-shadow: 0px 10px 30px rgba(62, 189, 252, 0.4);
  border-radius: 40px;
  color: @font-color;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;