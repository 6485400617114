@import "../../../../../variables.less";

.customerInfoTitle {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: @font-color;
  padding-bottom: 10px;
}

.customerInfoWrapper {
  padding: 20px;

  .customerInfoItem {
    margin: 0;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    color: @font-color;
  }

  .customerInfoItem > span {
    font-weight: normal;
    font-family: SFProText;
  }

  .noData {
    color: @font-grey-color;
  }
}

.cash-amount-popup-content {
  display: flex;
  flex-direction: column;
  width: 150px;

  input {
    width: 100%;
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
  }
}

.cash-amount-link {
  padding-left: 4px;
  color: cadetblue;
  text-decoration: underline;
  cursor: pointer;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;