.screens-container {
}

.cfr-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;