.driversStatisticsWrapper {
  padding: 40px 55px 40px 30px;
  height: auto;
  min-height: 100vh;
  position: relative;

  .estimatedTimeHeader {
    border-bottom: 3px solid #888;
    padding-bottom: 5px;
    text-align: center;
    margin-bottom: 5px;
  }

  .realTimeHeader {
    text-align: center;
    margin-bottom: 0;
  }

  .estimatedTime {
    padding-bottom: 5px;
    border-bottom: 3px solid #888;
    margin-bottom: 5px;
  }

  .realTime {
    margin-bottom: 0;
  }

  .filtersWrapper {
    display: flex;
  }

  .searchWrapper {
    display: flex;
    margin-right: 50px;

    .ant-select {
      margin-right: 8px;
      min-width: 200px;
    }
  }

  .orderLink {
    margin: 0;
    color: #008dff;
    cursor: pointer;
  }

  .driversStatisticsTableWrapper {
    border-radius: 5px;
  }
}

.driverstats-mobile-search {
  display: flex;
  height: 48px;

  .ant-select {
    height: 100%;
    text-align: left;
    border: none;
    background: #ffffff !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: rgba(1, 21, 40, 0.75);

    &-selector {
      width: 124px !important;
      border: none !important;
      padding-left: 16px !important;
    }

    &-selection-item {
      top: 8px;
    }

    &-focused {
      .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        color: rgba(1, 21, 40, 0.75);
      }
    }

    &-arrow {
      position: absolute;
      top: 24px;
      right: 7px;

      span > svg {
        fill: #011528;
      }
    }
  }

  .ant-input {
    height: 100%;
    background-color: #eeefef;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &::placeholder {
      color: rgba(1, 21, 40, 0.5);
      font-family: SFProText;
      font-weight: normal;
      font-size: 14px;
    }
  }

  .ant-rate {
    padding-left: 16px;
    padding-top: 2px;
    font-size: 26px;

    @media (max-width: 350px) {
      padding-left: 16px;
      padding-top: 6px;
      font-size: 20px;
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;