.reportPdf {
  padding: 25px;

  &__header {
    display: flex;
    justify-content: center;

    &__title {
      font-size: 20px;
      font-weight: bold;
      margin-right: 5px;
    }

    &__date {
      font-size: 20px;
    }
  }

  &__content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__general {
    width: 30%;

    &__title {
      font-weight: bold;
    }
  }

  &__Map {
    height: 680px;
    width: 100%;
    &__marker{
      width: 10px;
      height: 10px;
      background-color: #01cdfb;
      border-radius: 50%;
      border: 1px solid white;
      position: absolute;
      transform: translate(-50%, -50%);
      cursor: pointer;
      text-align: center;
      font-weight: bold;
    }
  }
}
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;