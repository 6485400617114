@import "../../../variables.less";

.registrationWrapper {
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
}
.stepsContainerWrapper {
  width: 100%;
  background: #fafafa;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 24px;
  @media (min-width: 1024px) {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    min-height: 100vh;
    height: auto;
    width: 355px;
    padding: 24px 0 0 50px;
  }
}
.registrationBBLogo {
  text-align: left;
  width: 100px;
}
.stepsWrapper {
  @media (min-width: 1024px) {
    height: 440px;
  }
}
.stepStyle > div > div::after {
  //  top: 12px;
  // left: 14px;
  display: none;
  @media (min-width: 1024px) {
    // top:0!important;
    display: flex;
  }
}
.stepsContentWrapper {
  .helpLinkWrapper{
    margin-left:auto;
  }
  padding: 15px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    padding: 28px 55px 55px 100px;
    flex: 1;
    margin: 0 auto;
  }
}
.bblogoHelplinkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 53px;
  padding-right: 15px;
  @media (min-width: 1024px) {
    margin-bottom: 93px;
  }
}
.stepsContent {
  padding: 40px 0;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;