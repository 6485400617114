.retailerInsights {
  &__Layout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 30px;
    margin-bottom: 30px;
    &__button {
      background-color: rgb(62, 208, 252);
      border-radius: 5px;
      font-weight: bold;
      width:200px;
      margin-right: 20px;
    }
  }
  &__graph {
    grid-column: span 3;
    grid-row: span 4;
    background-color: white;
    border-radius: 5px;
    padding: 30px;
 
    h3 {
      font-weight: bold;
      margin-bottom: 35px;
      display: flex;
      gap: 30px;
    }
  }
  &__total {
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    justify-content: space-evenly;
    color: rgba(34, 34, 34, 0.25);
    h3 {
      font-weight: bolder;
      font-size: xx-large;
      margin-bottom: 0px;
    }
  }
  &__mail {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    grid-row: span 3;
    border-radius: 5px;
    gap: 20px;
    padding: 10px;
    &__emailBox {
      display: flex;
      flex-wrap: nowrap;
      :first-child {
        flex-shrink: 1;
      }
    }
    &__input {
      display: flex !important;
      flex-wrap: nowrap;
    }
    &__list {
      width: 100%;
      overflow-y: auto;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .ant-input-group {
        width: 95%;
      }
    }
    &__header {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      h3 {
        font-weight: bold;
        flex-grow: 1;
        text-align: center;
      }
    }
    &__buttons {
      display: flex;
      width: 100%;
        .ant-btn {
          background-color: rgb(62, 208, 252);

        &-dangerous {
          background-color: white;
          span {
            background-color: white  !important;
          }
        }
      }

      button {
        border-radius: 3px;
        font-weight: bold;
        flex-grow: 1;
        width: 100%;
      }
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;