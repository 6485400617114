@font-color: #222222;
@light-grey: #e0e0e0;
@dark-grey: #cdcdcd;
@page-wrapper-bg: #F2F1F2;
@content-block-bg: #fff;

@font-grey-color: rgba(34, 34, 34, 0.3);
@primary-color: #01cdfb;
@secondary-color: #0096b8;
@bg-color: #01cdfb82;
@bg-light-color: #01cdfb45;

@link-color: #1877f2;
@link-bg-color: #1877f220;

@danger-color: #eb5757;
@danger-bg-color: #eb575720;

@success-color: #27ae60;
@success-bg-color: #27ae6020;

@dark-yellow: #01cdfb;
@dark-bg-yellow: #ffc70020;

@button-color: #222222;

.pageTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: @font-color;
}

.registrationStepsTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: @font-color;
}

.registrationFormStyle {
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 770px;
    width: 100%;
  }
}

.contentStyle {
  padding: 40px 24px;
  background: #fafafa;
  height: 100%;
  min-height: 90vh;

  @media (min-width: 768px) {
    padding: 48px 25px;
  }

  &.mapOpen {
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.adminPanelPageWrapper {
  //   background-color: #ffffff;
  padding: 40px 55px 40px 30px;
  height: auto;
  min-height: 100vh;
  position: relative;
}

.spinnerStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
}

.buttonStyle {
  width: 100%;
  box-shadow: 0 10px 30px rgba(2, 193, 252, 0.4);
  border-radius: 40px;
  color: #222;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.mobile-page-wrapper {
  height: 100% - 56px;
  max-width: 100vw;
  padding: 24px;
  background-color: #fafafa;
  position: relative;
}

.mobile-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  border-radius: 3px;
  border: none;
  font-family: SFProText;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  letter-spacing: 0.8px;

  &--blue {
    background: #1f85de;
    color: #ffffff;

    &:hover,
    &:focus {
      color: #ffffff;
      background: #1f85de;
    }
  }

  &--red {
    background: #eb5757;

    &:hover,
    &:focus {
      background: #eb5757;
    }
  }

  &--white {
    background: #ffffff;
    border: 1px solid rgba(34, 34, 34, 0.1);
    color: #222222;

    &:hover,
    &:focus {
      background: #ffffff;
      border: 1px solid rgba(34, 34, 34, 0.1);
      color: #222222;
    }
  }

  &--yellow {
    background: #01cdfb;
    color: #222222;

    &:hover,
    &:focus {
      background: #01cdfb;
      color: #222222;
    }
  }

  &--purple {
    background: #fdeeee;
    color: #eb5757;

    &:hover,
    &:focus {
      background: #fdeeee;
      color: #eb5757;
    }
  }

  &--gray {
    background: rgba(1, 21, 40, 0.05);
    color: #222222;
    margin-top: 16px;
    box-shadow: none;

    &:hover,
    &:focus {
      background: rgba(1, 21, 40, 0.05);
      color: #222222;
    }
  }

  &--orange {
    background: #fbaa29;
    color: #ffffff;
    box-shadow: none;

    &:hover,
    &:focus {
      background: #fbaa29;
      color: #ffffff;
    }
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-12 {
  margin-top: 12px;
}

.ant-btn-primary {
  color: @button-color;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: @button-color;
  }
}
