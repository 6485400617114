@import '../../../variables.less';

.draggerStyle {
  border: none !important;
}

.draggerStyle > span {
  padding: 0 !important;
}

.uploadedDocument {
  border: 2px dashed #01cdfb !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 2px;
  width: 270px;
  @media (min-width: 768px) {
    width: 322px;
  }
}

.uploadDragger {
  background: rgba(252, 220, 62, 0.05) !important;
  border: 2px dashed #01cdfb !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 31px;
  text-align: center;
}

.uploadText {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: @font-color;
  display: flex;
  flex-direction: column;

}

.uploadText > span {
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  color: rgba(34, 34, 34, 0.5);
}

.uploadHint {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: @font-color;
}

.deleteVideoButton {
  font-size: 20px;
  color: @primary-color;
  margin-left: 10px;
}

.videoStyle {
  outline: none;
  border-radius: 10px;
}

.draggerStyle iframe {
  border: none;
  // width: 300px;
  // height: 100px;
  margin: 0;
  padding: 0;
  opacity: 0.8;
  line-height: 0px;
  display: block; /* Add this */
}

.oldUploadedImg {
  width: 322px;
  border: 2px dashed #01cdfb !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 2px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;