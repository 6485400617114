.uploadRetailerDocumetWrapper {
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  @media (min-width: 768px) {
    width: 370px;
    padding: 24px;
  }
}

.commercial-checkbox {
  margin-top: 42px;

  .ant-checkbox-wrapper {
    margin-right: 6px;
  }

  .termsConditionals {
    text-decoration: underline;
    cursor: pointer;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;