.uploadOrdersDraggerWrapper {

        background: rgba(252, 220, 62, 0.05)!important;
        border: 2px dashed #01cdfb !important;
        box-sizing: border-box;
        border-radius: 10px !important;
        padding: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
}
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;