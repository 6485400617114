@import "../../../../utils/styleMixins";

.header {
  padding: 24px 32px;
  height: auto;
  line-height: 1;
  position: fixed;
  height: auto;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(14, 14, 14, 0.6);
  backdrop-filter: blur(16px);
  transition: background 500ms ease-in-out;
}
.mobileHeader-Expanded {
  background: black;
  transition: background 500ms ease-in-out;
}

.logInBtn {
  border-radius: 48px;
  border: none;
  transition: all 250ms linear;
  background: transparent;
  font-weight: 700;
  color: #01cdfb;

  .gradientAnimation(124.01deg, #01cdfb 19%, #21aeff 86.68%);
  background-image: linear-gradient(transparent, transparent);

  &:hover {
    cursor: pointer;
    backdrop-filter: blur(16px);
    color: #0a0a0b;
  }
}

.navMenu {
  justify-content: center;
  border: transparent !important;
  background: transparent;

  & li {
    color: #e9e9e9;
    font-weight: 300;
    font-size: 16px;
    line-height: 150% !important;

    & div[class="ant-menu-submenu-title"] {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      & svg[class="ant-menu-item-icon"] {
        fill: #e9e9e9 !important;
        font-size: 16px !important;
        min-width: 16px !important;
      }

      & span[class="ant-menu-title-content"] {
        margin-left: 0;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    &[class*="ant-menu-submenu-selected"] {
      font-weight: 700;
        color: #01cdfb !important;

      & div[class="ant-menu-submenu-title"] > svg[class="ant-menu-item-icon"] {
        fill: #01cdfb !important;
      }
    }

    &[class*="ant-menu-item-selected"] {
      color: #01cdfb !important;
      font-weight: 700;

      & a {
        color: #01cdfb !important;
      }
    }

    & a:not(.dropdownLink) {
      color: #e9e9e9;
    }

    &:hover a {
      color: #01cdfb !important;
    }

    &:hover div[class="ant-menu-submenu-title"] > svg[class="ant-menu-item-icon"] {
      fill: #01cdfb !important;
    }

    &:after {
      display: none;
    }
  }
}

.getInTouch:hover {
  color: #01cdfb !important;
}

.navMenuPopup {

  & ul[class*="ant-menu ant-menu-sub"] {
    border-radius: 16px;
    background: rgba(15, 179, 255, 0.35);  

    & li[class*="ant-menu-item"] {
      padding-bottom: 8px !important;
    }

    & li:active {
      background-color: transparent;
    }

    & li[class*="ant-menu-item-selected"] {
      background-color: transparent;

      & a {
        color: #01cdfb;
        font-weight: 600;
      }
    }

    & a:not(.dropdownLink) {
      color: #e9e9e9;
      font-weight: 300;

      &:hover {
        color: #01cdfb;
      }
    }
  }
}

.loginHint {
  margin-right: 8px;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  color: #2b2b2b !important;
}

.mobileMenu {
  width: 100%;
  height: 0vh;
  transition: height 500ms ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  min-height: 100%;
  &-Expanded {
    height: 91vh;
    position: relative;
  }
  & li {
    max-height: 15em;
    overflow: hidden;
    animation: deploy 2s;
  }
  & li[class*="ant-menu-item-selected"] {
    font-weight: 700;
    background-color: transparent !important;
    & a {
      color: #01cdfb !important;
    }
  }
}

.dropdownLink {
  color: rgba(86, 86, 86, 1) !important;
  font-weight: 300;
}

@keyframes deploy {
  from {
    max-height: 0;
  }
  to {
    max-height: 15em;
  }
}





 

 
@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;