.shareLocationWrapper {
  width: 245px;
  height: fit-content;
  background: #222222;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin: 20px auto 0;
  @media (min-width: 468px) {
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .shareLocationText {
      margin-bottom: 0;
    }

    button {
      margin-left: 20px;
    }
  }

  @media (min-width: 1311px) {
    display: block;
    width: 272px;
    padding: 40px;
    margin: auto;

    button {
      margin-left: 0;
    }
  }
}

.shareLocationText {
  color: #ffffff;
  text-align: justify;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.shareLocationIconStyle {
  margin: 34px 0;
  display: none;
  @media (min-width: 1311px) {
    display: flex;
  }
}

.shareLocationButtonStyle {
  box-shadow: 0px 10px 30px rgba(15, 179, 255, 0.4);
  border-radius: 40px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  height: 48px;
  width: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shareArrowIconStyle {
  margin-right: 5px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;