.getStartedWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.getStartedTitle {
  color: #e9e9e9 !important;
  font-weight: 300 !important;
  font-size: 64px !important;
  line-height: 76px !important;
  margin-bottom: 14px !important;
}

.getStartedTitleMobile {
  color: #e9e9e9 !important;
  font-weight: 300 !important;
  font-size: 32px !important;
  line-height: 38px !important;
  margin-bottom: 14px !important;
}

.rotatingTextWrap {
  text-align: center;
  margin-bottom: 48px;
  width: 100%;

  > span {
    color: #01cdfb !important;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
  }

  & span[class="react-rotating-text-cursor"] {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
  }

  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.rotatingTextWrapMobile {
  margin-bottom: 40px;

  > span {
    color: #01cdfb!important;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
  }

  & span[class="react-rotating-text-cursor"] {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
  }

  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;