.modal {
  div[class="ant-modal-content"] {
    background: #0a0a0b;
    border-radius: 24px;
    overflow: hidden;
  }

  span[class*="ant-modal-close-icon"] {
    color: #2b2b2b;
    transition: all 250ms linear;

    &:hover {
      color: #01cdfb;
    }
  }

  div[class="ant-modal-body"] {
    padding: 72px 106px;
  }
}

.modalTitle {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: #e9e9e9 !important;
  text-align: center;
  margin-bottom: 32px !important;
}

.modalTitleInsertion {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: #01cdfb !important;
}

.modalDescription {
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  text-align: center !important;
  color: #565656 !important;
  margin-bottom: 32px !important;
}

.signUpTypeWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signUpTypeCard {
  transition: all 250ms linear;
  background: transparent;
  border: none;
  border-radius: 16px;

  & span {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #e9e9e9;
    transition: all 250ms linear;
  }

  &:hover {
    cursor: pointer;
    background: rgba(22, 22, 22, 0.4);
    border-radius: 16px;

    & span {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: #01cdfb;
    }

    & .signUpTypeBusinessIcon::before {
      bottom: 18px;
      right: 2px;
    }

    & .signUpTypeDriverIcon::before {
      bottom: 18px;
      right: 4px;
    }

    & .signUpTypeUserIcon::before {
      opacity: 0;
      bottom: -2px;
      left: -2px;
    }

    & .signUpTypeUserIcon::after {
      opacity: 1;
      bottom: 10px;
      left: 2px;
    }
  }
}

.signUpTypeLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding: 24px 46px;
  align-items: center;
}

.signUpTypeIconWrap {
  position: relative;
  width: 88px;
  height: 88px;
  display: block;
  text-align: center;
  margin-bottom: 8px;
}

.signUpTypeIcon {
  content: "";
  display: inline-block;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 250ms linear;
}

.signUpTypeBusinessIcon {
  &::before:extend(.signUpTypeIcon) {
    width: 41px;
    height: 34px;
    bottom: 20px;
    right: 6px;
    z-index: 1;
    background-image: url("../../../../assets/newLanding/mainPage/signUpModal/business_static.svg");
  }
}

.signUpTypeDriverIcon {
  &::before:extend(.signUpTypeIcon) {
    width: 36px;
    height: 37px;
    bottom: 20px;
    right: 8px;
    z-index: 1;
    background-image: url("../../../../assets/newLanding/mainPage/signUpModal/driver_static.svg");
  }
}

.signUpTypeUserIcon {
  &::before:extend(.signUpTypeIcon) {
    width: 61px;
    height: 85px;
    bottom: 0px;
    left: 0px;
    opacity: 1;
    z-index: 2;
    background-image: url("../../../../assets/newLanding/mainPage/signUpModal/user_dynamic.svg");
  }

  &::after:extend(.signUpTypeIcon) {
    width: 44px;
    height: 65px;
    bottom: 12px;
    left: 7px;
    opacity: 0;
    z-index: 2;
    background-image: url("../../../../assets/newLanding/mainPage/signUpModal/user_hover.svg");
  }
}

.mobileModal {
  position: absolute;
  bottom: 0px;
  padding-bottom: 0px;
  top: unset;
  animation: slideUp 0.7s;
  width: 100vw !important;
  max-width: unset !important;
  margin-bottom: 0px !important;
  div[class="ant-modal-content"] {
    border-radius: 24px 24px 0px 0px !important;
  }
  div[class="ant-modal-footer"] {
    padding-bottom: 5px;
  }

  div[class="ant-modal-body"] {
    padding: 72px 40px;
  }
}

.mobileModalWrapper {
  overflow: hidden;
}

@keyframes slideUp {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;