@import "../../../../utils/styleMixins";

.forgotPasswordWrap {
  position: fixed;
  height: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.promoSection {
  background: #0e0e0e;
  padding-top: 29px;

  background-image: url("../../../../assets/newLanding/auth/passwordRecoveryBg.svg");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: left 80px bottom 80px;
  height: 100vh;
}

.promoSectionTitle {
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 60px !important;
  color: #e9e9e9 !important;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 0 !important;
  margin-left: 80px;
}

.promoSectionSubtitle {
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 60px !important;
  color: #01cdfb !important;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 0 !important;
}

.mainSection {
  padding-top: 29px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.signUpButton {
  border-radius: 48px;
  border: none;
  transition: all 250ms linear;
  background: transparent;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #0a0a0b;
  display: flex;
  align-items: stretch;

  .gradientAnimation(124.01deg, #01cdfb 19%, #21c0ff 86.68%);
  background-image: linear-gradient(transparent, transparent);

  &:hover {
    cursor: pointer;
    backdrop-filter: blur(16px);
    color: #0a0a0b;
  }
}

@media (min-width: 1024px) {
  .forgotPasswordWrap {
    height: auto;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .tablet_img {
    display: none;
  }
}

@media (max-width: 820px) {
  .forgotPasswordWrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
  }

  .forgotPasswordWrap {
    position: none;
  }
  .promoSection {
    width: 100%;
    background-image: none;
    height: 370px;
  }

  .promoSectionTitle {
    text-align: center;
    margin-left: 0;
  }

  .tablet_img {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    z-index: 600;
  }

  .btn_tablet {
    margin: 0 auto;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;