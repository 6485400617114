.title {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  margin-bottom: 0 !important;
}

.successTitle {
  .title;

  color: #0a0a0b !important;
}

.successSubtitle {
  .title;

  color: #01cdfb !important;
}

.successMessage {
  margin-top: 27px !important;
  margin-bottom: 0 !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  line-height: 150% !important;
  letter-spacing: 0.01em !important;
  color: #0a0a0b !important;
}

.backToMainPageBtn {
  padding: 12px 32px !important;
  margin-top: 40px !important;
  width: auto !important;

  & a {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #0a0a0b;

    & svg {
      stroke: #0a0a0b;
      margin-right: 10px;
    }
  }

  &:active a {
    color: #0a0a0b !important;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;