.warehouseMarkerContainer {
  transform: translate(-50%, -50%);
  position: relative;
  left: 0px;
  top: -19px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.zone-button-container {
  display: flex;
  .ant-btn-dangerous {
    // padding:auto;
    display: flex;
    padding: 4px 10px !important;
    width: 32px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;