.clientsArticle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.clientsTitle {
  font-weight: 300 !important;
  font-size: 48px !important;
  line-height: 125% !important;
  color: #e9e9e9 !important;
  margin-bottom: 72px !important;
  &Mobile {
    font-weight: 300 !important;
    font-size: 26px !important;
    line-height: 120% !important;
    color: #e9e9e9 !important;
    margin-bottom: 36px !important;
    width: 100%;
  }
}

.clientsTitleInsertion {
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 60px !important;
  color: #01cdfb !important;
  &Mobile {
    font-weight: 600 !important;
    font-size: 26px !important;
    line-height: 120% !important;
    color: #01cdfb !important;
  }
}

.sliderWrap {
  position: relative;
  width: 100%;
  height: 110px;
  &Mobile {
    position: relative;
    width: 100%;
    height: 110px;
  }
}

.slider {
  overflow: visible;
}

.clientImg {
  width: 139px;
  height: 110px;
  &Mobile {
    width: 139px;
    height: 110px;
    padding: 20px;
  }
}

.controlWrap {
  border-radius: 8px;
  display: flex;
  position: absolute;
  top: 25%;
  bottom: 25%;
  right: 0;
}

.controlBtn {
  background: rgba(35, 35, 35, 0.4) !important;
  backdrop-filter: blur(16px) !important;
  width: 44px;
  height: 48px;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    transition: all 250ms linear;
    fill: #e9e9e9;
  }

  &:disabled {
    cursor: not-allowed;

    & svg {
      fill: #fdfdfd;
      fill-opacity: 0.6;
    }
  }

  &:hover:not(:disabled) svg {
    fill: #01cdfb;
  }

  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;