.combine-job-header {
  margin-top: 10px;
}

.combine-jobs-action-wrapper {
  min-height: calc(100vh - 200px);
}

.job-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;

  .header-actions {
    display: flex;
  }

  .ant-typography {
    margin: 0;
  }
}

.job-tag-wrapper {
  margin-bottom: 20px;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;