@import "../../../variables.less";

.save-setting-btn {
    background-color: #222;
    border: #222;
    color: whitesmoke;
    height: 40px;
    width: 100%;
    margin-top: 10px;
}

.ant-input-number-suffix span{
    margin-inline: 10px;
}

.number-input-style {
    width: 100%;
}

.delete-setting-btn {
    width: 100%;
    background-color: #f28585;
    border: #f28585;
    color: @content-block-bg;
}

.delete-setting-btn:hover {
    background-color: transparent;
    border: #f28585;
    color: #f28585;
}

.save-setting-btn:hover {
    background-color: #222;
    color: whitesmoke;
}


@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;