.selected-jobs-tag-wrapper {
  background-color: #f4f4f4;
  padding: 20px;
  margin-top: 20px;
  border-radius: 4px;
}
.combine-btn {
  width: 180px;
  margin: 20px auto;
  display: block;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;