@import "../../variables.less";
// table
.logs-table {
  .ant-table-thead {
    .ant-table-cell {
      background: rgba(0, 183, 255, 0.3);
      border-right: 2px white solid;
      font-weight: bold;
    }
    > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: transparent !important;
    }
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 2px 8px !important;
  }

  .changes_wrapper {
    align-items: center;

    &--info,
    &--warn,
    &--important {
      margin-right: 5px;
      font-size: medium;
    }

    &--info {
      svg path {
        fill: @dark-grey;
      }
    }

    &--warn {
      svg path {
        fill: @danger-color;
      }
    }

    &--important {
      svg path {
        fill: @success-color;
      }
    }
  }
}

 
.activity_toolbar {
  justify-content: end;
  &--search {
    max-width: 60%;
    margin-right: 10px;
    border-bottom: solid 0.5px @light-grey;
  }

  &--filtersBtn {
    .anticon {
      margin-right: 5px;
      svg path {
        fill: #000;
      }
    }

    color: #000;
    margin-right: 10px;
    &:hover {
      color: #000;
    }
  }
}

.order-history-drawer {
  &__header {
    margin-bottom: 10px;
    h3 {
      font-weight: 600;
    }
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;