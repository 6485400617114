.footer {
  padding: 0;
  background: rgba(22, 22, 22, 0.4);
  border-top: 1px solid #2b2b2b;
  display: flex;
  justify-content: center;
}

.footerFirstLevel {
  padding: 72px 0 75px 0;
  max-width: 1200px !important;
  margin: auto;

}
.footerSecondLevelRow {
  max-width: 1200px !important;
}

.mobileAppTitle {
  color: #e9e9e9;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
}

.mobileAppLinksWrap {
  display: flex;
  flex-direction: column;
}

.mobileAppLink {
  display: inline-block;
  line-height: 1;

  &:first-child {
    margin-bottom: 11px;
  }

  & svg {
    fill: #e9e9e9;
    transition: all 250ms linear;
  }

  &:hover svg {
    cursor: pointer;
    fill: #01cdfb;
  }
}

.footerSectionTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #e9e9e9;
  margin-bottom: 24px !important;
}

.footerItem {
  padding: 0 0 23px 0;
  border-bottom: none !important;

  & a,
  & div[class="ant-typography"] {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #565656;
    cursor: default;
    pointer-events: none;
    margin-bottom: 0 !important;

    &:hover {
      cursor: default;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.footerActiveItem {
  & a,
  & div[class="ant-typography"] {
    pointer-events: all;

    &:hover {
      cursor: pointer;
      color: #01cdfb;
    }
  }
}

.footerSecondLevel {
  background: #0e0e0e;
  padding: 6px 0 8px 0;
}

.copyright {
  color: #2b2b2b;
  font-weight: 600;
}

.socialLinksWrap {
  line-height: 1;
  &Mobile {
    height: 50px;
    justify-content: space-evenly;
    & svg {
      width: 50px;
      height: 50px;
    }
  }
}

.socialLink {
  display: inline-block;
  pointer-events: none;

  &:not(:last-child) {
    margin-right: 12px;
  }

  & svg {
    fill: #565656;
    transition: all 250ms linear;
  }

  &:hover svg {
    cursor: default;
  }
}

.socialLinkActive {
  pointer-events: all;

  &:hover svg {
    cursor: pointer;
    fill: #01cdfb;
  }
}

.mobileFooterLink {
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: #565656;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;