.gradientAnimation( @deg, @start, @end ) {
  background-size: 100%;
  position: relative;
  z-index: 1;
  background-image: linear-gradient(@deg, @start, @end);

  &:before {
    background-image: linear-gradient(@deg, @start, @end);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -1;
    transition: opacity 250ms linear;
    backdrop-filter: blur(16px);
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
