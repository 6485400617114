.stepsWidth {
  margin-top: 34px;

  .ant-steps-item {
    max-width: 100% !important;
    padding-left: 0 !important;
    flex: unset;
    width: 113px;

    &:first-child {
      margin-left: 0;
    }

    &-title {
      &::after {
        top: 7px;
        left: 0px;
        background-color: inherit !important;
        background: url("../../../../assets/wait-step-line.svg") no-repeat;
        background-size: 100% 100%;
        height: 10px;
        width: 68px;
      }
    }

    @media (max-width: 768px) {
      width: 300px;

      &-title {
        padding-top: 4px;
      }

      &:last-child {
        .ant-steps-item-container,
        .ant-steps-item-content,
        .ant-steps-item-title {
          height: 30px;
        }
      }
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-title {
      &::after {
        background: url("../../../../assets/active-step-line.svg") no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-title {
      &::after {
        background: url("../../../../assets/finish-step-line.svg") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

.ant-steps-vertical {
  @media (max-width: 768px) {
    .ant-steps-item-tail {
      &::after {
        width: 2px !important;
      }
    }

    .ant-steps-item-process {
      .ant-steps-item-tail {
        &::after {
          position: absolute;
          top: 30px;
          left: -3.6px;
          background: url("../../../../assets/active-step-line-vertical.svg") no-repeat !important;
          background-size: 100% 100% !important;
          height: 42px !important;
          width: 10px !important;
        }
      }
    }
  }
}

.stepDescription {
  display: none;
}

.stepTitle,
.stepDescription {
  margin-bottom: 45px;
  font-size: 14px;
  line-height: 16px;
  color: #222222;

  @media (min-width: 768px) {
    margin-left: -30px;
    margin-top: 30px;
    width: 80px;
    margin-bottom: 0px;
  }
}
.activeStepTitle {
  font-weight: bold;
}

@media (min-width: 768px) {
  .stepDescription {
    display: flex;
  }
  .stepTitle {
    display: none;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;