.modal {
  div[class="ant-modal-content"] {
    background: #0a0a0b;
    border-radius: 24px;
    overflow: hidden;
  }

  span[class*="ant-modal-close-icon"] {
    color: #2b2b2b;
    transition: all 250ms linear;

    &:hover {
      color: #01cdfb;
    }
  }

  div[class="ant-modal-body"] {
    padding: 32px 8px 32px 0 !important;
  }
}

.scrollAreaWrap {
  overflow-y: scroll;
  padding: 0 46px 0 54px;
  height: 541px;

  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-track {
    background: #0a0a0b;
    border-radius: 16px;
    margin-top: 65px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(68, 68, 68, 0.4);
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(22, 22, 22, 0.9);
  }
}

.modalTitle {
  font-weight: 400 !important;
  font-size: 34px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  color: #e9e9e9 !important;
}

.modalSubTitle {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  color: #01cdfb !important;
  margin-bottom: 16px !important;
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 24px;
    background: #01cdfb;
    position: absolute;
    top: 4px;
    left: -53px;
  }
}

.modalText {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #e9e9e9;
  margin-bottom: 16px;
}

.modalSubtext:extend(.modalText) {
  font-weight: 700;
}

.insertion:extend(.modalText) {
  color: #01cdfb;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;