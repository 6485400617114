.trackingInfoItem {
  margin: 0 5px;
}

.trackingInfoItemTitle {
  font-size: 14px;
  color: rgba(34, 34, 34, 0.3);
}

.trackingInfoItemValue {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #222222;
  font-weight: bold;
  background: #FFFFFF;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  padding: 16px 12px;
  min-width: 235px;
  @media (min-width: 768px) {
    padding: 16px 24px;
    font-size: 16px;
    min-width: 290px;
  }
}

.trackingInfoAdditional {
  font-size: 10px;
  color: #01cdfb;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;