.comment-container {
  height: 250px;
  overflow-y: auto;
}

.chat-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .send-btn {
    margin-left: 20px;
  }
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;