.orderRow {
  &--preview {
    background-color: #cce8ff;
  }
}
.orderTable--toolbar {
  margin-top: 10px;
  padding: 5px 0px;
  .ant-btn {
    margin-right: 5px;
  }
}

table tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

table tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

@primary-color: #01cdfb;@button-color: #222;@link-color: #1890ff;